import { AvisVerifie } from '@homebox/client';
import { GetServerSidePropsResult, NextPage } from 'next';
import HomePage, { HomePageProps } from '@/ui/pages/homepage';
import createServerPropsWithAppData from '@/utils/createServerPropsWithAppData';

function getEuQuotes(): AvisVerifie[] {
    return [
        {
            '@type': 'AvisVerifie',
            createdAt: '2023-02-07T00:00:01.943Z',
            updatedAt: '2023-02-07T00:00:01.943Z',
            id: 0,
            lastname: 'B.',
            firstname: 'Nicolas',
            description: 'The storage units are clean and secure. You can store your belongings there with peace of mind ! An expert and friendly staff here to advise you!!',
            rate: 5,
            date: '2023-02-07T00:00:01.943Z',
            center: null,
            review_id: '',
        },
        {
            '@type': 'AvisVerifie',
            createdAt: '2023-10-14T00:00:01.943Z',
            updatedAt: '2023-10-14T00:00:01.943Z',
            id: 0,
            lastname: 'A.',
            firstname: 'Valérie',
            description: 'Excellent service, caring staff proving good advice. I recommend you to book here !',
            rate: 5,
            date: '2023-10-14T00:00:01.943Z',
            center: null,
            review_id: '',
        },
        {
            '@type': 'AvisVerifie',
            createdAt: '2023-04-09T00:00:01.943Z',
            updatedAt: '2023-04-09T00:00:01.943Z',
            id: 0,
            lastname: 'L.',
            firstname: 'Mathilde',
            description: 'Very professional service, helpful staff with good recommendations for removals people',
            rate: 5,
            date: '2023-04-09T00:00:01.943Z',
            center: null,
            review_id: '',
        },
        {
            '@type': 'AvisVerifie',
            createdAt: '2024-03-18T00:00:01.943Z',
            updatedAt: '2024-03-18T00:00:01.943Z',
            id: 0,
            lastname: 'C.',
            firstname: 'Axel',
            description: 'I rent there because of the tremendous excellence of their expertise to provide the right unit for your needs',
            rate: 5,
            date: '2024-03-18T00:00:01.943Z',
            center: null,
            review_id: '',
        },
    ];
}

export const getServerSideProps = createServerPropsWithAppData(async ({
    appData, website,
}): Promise<GetServerSidePropsResult<HomePageProps>> => {
    const [
        page,
        centers,
        funnelId,
        frQuotes,
        quoteAverage,
    ] = await Promise.all([
        appData.getHomepage(),
        appData.getAllCentersForMap(),
        appData.getCurrentFunnelId(),
        appData.getAvisVerifies(),
        appData.getAvisVerifiesAverage(),
    ]);

    const quotes = website.isEurope ? getEuQuotes() : frQuotes.data;

    return {
        props: {
            page,
            quotes,
            quoteAverage,
            centers,
            funnelId,
        },
    };
});

const Page: NextPage<HomePageProps> = HomePage;
export default Page;
